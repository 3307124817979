.header-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

#fonts-abs,
#bold {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

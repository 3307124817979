@font-face {
    font-family: "HelveticaNowDisplay-Bold";
    src: url("../public/assets/fonts/HelveticaNowDisplay-Bold.otf")
        format("opentype");
}
@font-face {
    font-family: "HelveticaNowDisplay-Medium";
    src: url("../public/assets/fonts/HelveticaNowDisplay-Medium.otf")
        format("opentype");
}
@font-face {
    font-family: "HelveticaNowDisplay-Regular";
    src: url("../public/assets/fonts/HelveticaNowDisplay-Regular.otf")
        format("opentype");
}

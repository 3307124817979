.dot {
    display: inline-block;
    overflow: hidden;
    transition: width 0.2s ease-in-out;
    vertical-align: middle;
}

/* VideoPlayer.css */
.video-container {
    position: relative;
    width: 100px;
    height: 100px;
}

.circle {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ccc;
    transition: all 0.3s ease;
}

.preview {
    z-index: 1;
}

.play-button {
    z-index: 2;
    cursor: pointer;
}

.square {
    width: 100px;
    height: 100px;
    background-color: #ccc;
    /* Additional styles for video display */
}

/* VideoPlayer.css */

/* Animation for circle to square transition */
.circle {
    transition: all 0.5s ease;
}

.square {
    width: 100px;
    height: 100px;
    background-color: #ccc;
    transition: all 0.5s ease;
}

/* Scale animation example */
.circle.preview {
    transform: scale(1);
}

.square.video {
    transform: scale(1);
}

/* Infinite Text */
#animated-text-strip {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    overflow: hidden;
}
#animated-text-strip .marquee {
    white-space: nowrap;
    animation: marquee 10s linear infinite;
    max-width: none;
    display: inline-block;
    padding-right: 48px;
}
#animated-text-strip .marquee img {
    display: inline-block;
    padding-right: 8px;
    vertical-align: middle;
}
#animated-text-strip .marquee p {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 1px;
    font-size: 28px;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

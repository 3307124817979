@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-moz-selection {
    /* Code for Firefox */
    background: rgba(208, 255, 113, 0.6);
}

::selection {
    background: rgba(208, 255, 113, 0.6);
}

.no-scroll {
    overflow: hidden;
}

body::-webkit-scrollbar {
    width: 6px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    background-color: transparent; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 6px solid #d0ff718a; /* creates padding around scroll thumb */
}
